$color-dark-blue: #000033;
$color-purple: rgb(102, 51, 204);
$color-yellow: rgba(255, 204, 51, 1);
$color-dark-yellow: rgb(230, 190, 72);
.bg-yellow {
  background-color: $color-yellow;
}

.pointer {
  cursor: pointer;
}

.toast-container {
  position: fixed !important;
  z-index: 999 !important;
}

.bg-purple {
  background-color: $color-purple;

  &.btn:hover {
    background-color: $color-purple;
  }
}

.bg-gradient-purple {
  background: linear-gradient(167.27deg, #9b4cd9 4.65%, #7d4bdf 96.64%);
}

.bg-gradient-light-purple {
  background: linear-gradient(153.74deg, #f6f2ff -2.48%, #ffffff 100%);
}

.bg-dark-blue {
  background-color: $color-dark-blue;
}

.text-yellow {
  color: $color-yellow;
}

.text-blue {
  color: $color-dark-blue;
}

.text-purple {
  color: $color-purple;
}

// textes de tailles de 32px sur la maquette
.text-32 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
}

// textes de tailles de 48px sur la maquette
.text-48 {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 150%;
}

.text-36 {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 106.6%;
}

// textes de tailles de 24px sur la maquette
.text-24 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150.1%;
}

// textes de tailles entre 60px et 64px sur la maquette
.text-60 {
  font-style: normal;
  font-weight: 300;
  font-size: 35px !important;
  line-height: 106.6%;
  @media (max-width: 575.98px) {
    font-size: 30px !important;
    line-height: 120% !important;
  }
}

// textes de tailles de 32px sur la maquette avec une font-weight de 800px
.text-32-bold {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 150%;
}

// textes de tailles de 24px sur la maquette avec une font-weight de 800px
.text-24-bold {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 150.1%;
}

// textes de tailles entre 60px et 64px sur la maquette avec une font-weight de 800px
.text-60-bold {
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 106.6%;
}


.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  background-color: transparent !important;
}

.name-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
  background: #390F8E;
  width: 39px;
  height: 39px;
  border-radius: 50%;

  p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 106.6%;
    color: #FFFFFF;
    margin: 0;
  }
}


